import { defineStore } from "pinia";
import axios from 'axios'
import { useRuntimeConfig } from "nuxt/app";
import {useUserStore} from '../user.store'
import helper from "~/resources/js/libraries/helper";

export const useOffersStore = defineStore("offers", {
	state: () => ({
        offers: [] as any[],
        offer: {} as {[key: string]: any},
        form: [] as any[],
	}),
    getters: {
        offersGetter: (state) => () => state.offers,
        offerGetter: (state) => () => state.offer,
        formGetter: (state) => () => state.form,
	},
	actions: {
        getVcrmAccountToken() {
			if (!document) {
				return false;
			}
			const vue_cookie = document.cookie.split("; ").find((row) => row.startsWith("vcrm-account-token"));
			if (vue_cookie != undefined && vue_cookie.split("=")[1] != "") {
				return vue_cookie.split("=")[1];
			}
		},

        async fetchOffers() {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            const userStore = useUserStore()

            const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;				
			}

            axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
            axios.defaults.headers['application-platform'] = 'web';

            await axios.get(`${accountApiUrl}/customer-offers`)
                .then((res) => {
                    this.offers = res.data.data
                    return res.data
                })
                .catch(err => {
                    this.offers = []
                    return []
                })
        },

        async fetchOfferById(id: string) {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;				
			}

            axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
            axios.defaults.headers['application-platform'] = 'web';

            return await axios.get(`${accountApiUrl}/customer-offers/${id}`)
                .then((res) => {
                    this.offer = res.data
                    return res.data
                })
                .catch(err => {
                    this.offer = {}
                    return {}
                })
        },

        async fetchOffersForm(category_id: number) {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;				
			}

            axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
            axios.defaults.headers['application-platform'] = 'web';

            await axios.get(`${accountApiUrl}/customer-offers/${category_id}/form`)
                .then((res) => {
                    this.form = res.data
                    return res.data
                })
                .catch(err => {
                    this.form = []
                    return []
                })
        },

        async addOffer(form: { [key: string]: any }) {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;				
			}

            axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
            axios.defaults.headers['application-platform'] = 'web';

            const userStore = useUserStore()

            const country_id = userStore.userGetter()?.country_id;

            form['country_id'] = country_id

            return await axios.post(`${accountApiUrl}/customer-offers`, form)
        },

        async updateOffer(id: string, form: { [key: string]: any }) {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;				
			}

            axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
            axios.defaults.headers['application-platform'] = 'web';

            const userStore = useUserStore()

            const country_id = userStore.userGetter()?.country_id;

            form['country_id'] = country_id

            return await axios.put(`${accountApiUrl}/customer-offers/${id}`, form)
        },

        async deleteOffer(id: string) {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;				
			}

            axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
            axios.defaults.headers['application-platform'] = 'web';

            return await axios.delete(`${accountApiUrl}/customer-offers/${id}`)
        }
    }
});